<template>
  <div class="admin-wizard">
    <AdminHeader />
     <AdminBreadcrumb
     @on-saveContinue="onFormSubmit(true)"
     />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card>
                <b-card-body class="p-0">
                  <b-card-title>
                    Fill out your email address.
                  </b-card-title>
                  <div class="guide-tip">
                    <b-row align-v="center">
                      <b-col cols="3" lg="1" class="icon">
                        <font-awesome-icon :icon="['far', 'envelope']"/>
                      </b-col>
                      <b-col cols="12" lg="8" order="3" order-lg="2" class="text">                      
                        Your email will not be displayed on your site. It will only be used so that you may receive comments, petition signatures, and endorsements.
                      </b-col>
                      <b-col cols="9" lg="3" order="2" order-lg="3" class="button">
                        <b-button variant="outline-secondary" size="sm" v-b-modal.modal-theme-guide>
                          View Example <font-awesome-icon :icon="['far', 'window-maximize']"/>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>                  
                  <b-alert :show="!!errorMessage" variant="danger">{{
                    errorMessage
                  }}</b-alert>
                  <b-form @submit.prevent="onFormSubmit">
                    <b-form-group>
                      <ValidationProvider
                        name="Email"
                        rules="required|email"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-email"
                          v-model="candidateModel.contactEmail"
                          placeholder="Enter contact email"                          
                          :state="getValidationState(validationContext)"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <b-button
                      v-show="false"
                      ref="submit"
                      type="submit"
                      variant="primary"
                      >Submit</b-button
                    >
                  </b-form>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onSave"
                @on-back="onBack"
                @on-preview="onPreview"
                @on-skip="onSkip"
                v-bind:hide-preview-button="true"
                v-bind:hide-skip-button="true"
                :saving="saving"
                :invalid="invalid"
                :hideSkipButton="candidateTemp.contactEmail ? false : true"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="email"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
export default {
  name: "ContactEmail",
  mixins: [DirtyFlagMixin, CandidateMixin],
  data() {
    return {
      hideSkipButton: true,
      email: null,
      loading: false
    };
  },
  methods: {
    onPreview() {},
    onBack() {
      this.$router.push("theme");
    },
    onSkip() {
      this.$router.push("name-position");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        email: this.candidateModel.contactEmail
      };

      this.isSubmitting = true;
      this.saving = true;

      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.candidateService
            .SaveEmailAsync(payload)
            .then(res => {
              setTimeout(() => {
                this.$store.commit("candidates/SET_CANDIDATE", res.data);
                this.saving = false;
                if(fromBreadcrumb != true){
                  this.$router.push("name-position");
                }
                
              }, 500);
            })
            .catch(e => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
